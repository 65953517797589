<template>
  <div >
    <el-dialog title="导入" :visible.sync="visible" width="460px" :before-close="handleClose" append-to-body>
      <el-form :model="form" ref="elFormDom" :rules="form.rules" label-position="top">
        <el-form-item>
          <a :href="objData.sampleFile">
            <el-button type="primary" plain>下载样表</el-button>
          </a>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            action=""
            :headers="{token:token}"
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            :http-request="httpRequestBack"
            accept=".xls,.xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      append-to-body
      >
        <div style="font-weight: bold;margin-bottom:10px;">导入成功：{{templateInfo.success}} 条</div>
        <div>以下商品信息导入失败：</div>
        <div v-if="templateInfo.errors.length > 0" class="errorBox">
          <div class="err-list" v-for="(item, index) in templateInfo.errors" :key="index">{{index +1}}、{{item}}</div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStorage } from '@/storage'
export default {
  name: 'ImportDailog',
  data() {
    return {
      token: getStorage('token'),
      visible: false,
      dialogVisibleImport: false,
      objData: {
        sampleFile: '', // 样表
      },
      form: {
        loading: false,
        data: {

        }
      },
      fileList: [],
      curfile: '',
      // 导入后提示
      errorDialogVisible: false,  // 导入异常弹窗
      templateInfo: {
        success: 0,
        errors: [],
      },
      listArr: [],
    }
  },
  methods: {
    getDetail(obj) {
      console.log(obj,"obj")
      this.visible = true;
      this.objData = obj;
    },
    reset(done) {
      this.isChange = false;
      this.isCopy = false;
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    httpRequestBack(file) {
      this.curfile = file.file;
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          if(this.curfile) {
            let formData = new FormData();
            formData.append('file', this.curfile);
            this.form.loading = true;
            this.$http.post('/admin/stock/importGoods', {formData, type:'upload'}).then(res => {
              if(res.code == 1) {
                // 导入失败的就弹窗显示错误信息并能下载文本
                if(res.data.errors.length > 0) {
                  this.errorDialogVisible = true; // 打开反馈错误信息弹窗
                  this.templateInfo.errors = res.data.errors; //上传失败数据
                  this.templateInfo.success = res.data.list.length; // 上传成功条数
                } else {
                  this.$message.success('导入成功！')
                }
                this.handleClose(); // 关闭导入弹窗
                this.listArr = res.data.list.map(v => {
                  return {
                    good_id: v.goods_id, //商品id
                    good_name: v.goods_name, // 商品名称
                    code: v.code, // 商品编码
                    order_cate_name: v.cate_name, // 下单分类
                    image: v.images, // 商品图片
                    spec: v.spec, // 规格数组
                    quantity: v.num,//实际库存
                    name: (v.spec.length == 1 && v.error == 0) ? v.spec[0].name : '', // 规格名称
                    id: (v.spec.length == 1 && v.error == 0) ? v.spec[0].id : 0, // 规格id
                    unit_name: (v.spec.length == 1 && v.error == 0) ? v.spec[0].unit_name : '', //单位
                    sys_quantity: (v.spec.length == 1 && v.error == 0) ? v.spec[0].sys_quantity : '', //系统库存
                    type: (v.spec.length > 1 || v.error == 2) ? 2 : 1, // 1文本，2：下拉框
                    errText: v.error == 0 ? '' : (v.error == 1 ? '商品名称不存在' : '商品单位不存在') // v.error: 0:正常商品，1：商品名称不存在，2：商品单位不存在
                  }
                })
                console.log(this.listArr,"this.listArr")
                // 将导入成功的数据传递给父组件
                this.$emit('sendgoodslist',this.listArr)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          } else {
            this.$message.warning('请选择要导入的文件')
          }
        }
      })
    },
    errorExp() {
      const text = this.templateInfo.errors;
      const blob = new Blob([text], {type:'text/plain'});
      // 创建a标签元素
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob)
      link.download ='file.txt';
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    removeFile() {
      this.fileList = [];
    },
    // 关闭导入弹窗
    handleClose() {
      this.toggle(false)
      this.fileList = [];
      this.curfile = '';
    },
    errorClose() {
      this.errorDialogVisible = false;
    },
  }
}
</script>
<style scoped>
.err-list {
  margin-top: 10px;
}
</style>